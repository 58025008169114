import React from 'react';
import Layout from './index';
import { addLocaleData } from 'react-intl';

import messages from '../data/messages/de';
import de from 'react-intl/locale-data/de';
import 'intl/locale-data/jsonp/de';

addLocaleData(de);

export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />);